<template>
  <div data-app>
    <div class="page-title pageheading" style="height: 50px">
      <h3 class="titlename">
        <i class="menu-icon flaticon-clock-2"></i
        ><span class="ml-3 text-color">Employee Contact Summary</span>
      </h3>
      <span class="float-right green-btn">
        <a class="btn btn-success mr-3" @click="Apply">
          <i class="material-icons">add</i> <span>Create</span></a
        >
      </span>
    </div>
  </div>
</template>
<script>
import BillingReport from "../../components/reprt/billingReport.vue";
import { mapGetters } from "vuex";
export default {
  components: { BillingReport },

  data() {
    return {
      form: {},
    };
  },
  watch: {},
  created() {},
  computed: {
    ...mapGetters(["EmployeeReport"]),
  },
  mounted() {},
  methods: {
    dobformat2(obj) {
      if (obj) {
        var date = new Date(obj);
        return (
          (date.getMonth() > 8
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
          "/" +
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
          "/" +
          date.getFullYear()
        );
      }
    },
    ExportBilling() {
      var filename = "Employee_Contact_List";
      var data = [];
      var header = "";
      data.push(`Employee Contact List`);

      header = `"Last Name","First Name","Employee Type","Employee ID","Status","DOB","Address","Unit/aApt #","Email","Phone Number","Gender","Hire Date"`;
      data.push(header);
      this.EmployeeReport.forEach((x) => {
        var row = `"${x.lastName}","${x.firstName}","${x.employeeType}","${x.empID}","${x.status}","${this.dobformat2(x.dob)}","${x.address}","${x.otherAddress}","${x.email}","${x.phoneNumber}","${x.gender}","${this.dobformat2(x.hireDate)}" `;
        data.push(row);
      }); 
      this.downloadCSVFile(data.join("\n"), filename);
    },
    downloadCSVFile(csv, filename) {
      var csv_file, download_link;

      csv_file = new Blob([csv], { type: "text/csv" });

      download_link = document.createElement("a");

      download_link.download = filename;

      download_link.href = window.URL.createObjectURL(csv_file);

      download_link.style.display = "none";

      document.body.appendChild(download_link);

      download_link.click();
    },
    async Apply() { 
        this.loader = true;
        await this.$store
          .dispatch("getEmployeeReport")
          .then((response) => {
            this.isfetching = false; 
            if (response.length > 0) {
              this.ExportBilling();
            } else {
              this.loader = false;
              Swal.fire({
                title: "",
                text: "No Record Found",
                icon: "error",
                confirmButtonClass: "btn btn-danger",
              });
            }
          })
          .catch((ex) => {
            this.isfetching = false;
            
          }); 
    },
  },
};
</script>
<style scoped>
.titlename {
  float: left !important;
  padding-left: 19px;
}
.pageheading {
  padding-top: 20px;
  padding-bottom: 50px;
  height: 50px;
  background-color: #ffffff;
}
.text-color {
  color: #2d79bd;
}
</style>


